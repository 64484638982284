@media(min-width:480px) and (max-width:868px){
    .profilepage{
        /* background-color: #DA18A3; */
        height: 170vh;
    }
    .navprofile{
        position: fixed;
        width: 100%;
        z-index: 998;
        display: none;
    }
    .profiletopbackground{
        /* position: absolute;
        top: 0px; */
        height: 20vh;
        width: 100%;
    }
    .profilebgimg{
        height: 20vh;
        width: 100%;
        object-fit: cover;
    }
    .profiletopimage{
        height: 20vh;
        width: 100%;
    }
    .profileimageimg{
        position: absolute;
        top: 10vh;
        left: 42.5vw;
        height: 15vw;
        width: 15vw;
        border-radius: 7.5vw;
        object-fit: cover;
        border: 4px solid black;
    }
    .profileoptions{
        display: flex;
        margin-top: 0vh;
        justify-content: space-between;
    }
    .profilesearch{
        margin-top: 30vh;
        margin-left: 15vw;
        height: 5vh;
        width: 50%;
        display: flex;
        /* background-color: aqua; */
        border-radius: 5px;
        border:2px solid #E3E1E3;
    }
    .profilerecently{
        margin-top: 30vh;
        margin-right: 15vw;
        height: 5vh;
        width: 10%;
        display: flex;
        /* background-color: aqua; */
        border-radius: 5px;
        border:2px solid #E3E1E3;
    }
    .searchicon{
        height: 3vh;
        margin-top: 1vh;
        width: 1.5vw;
    }
    .searchbarexplore{
        margin-top: 1vh;
        height: 3vh;
        width: 100%;
        border: none;
    }
    .hometophotbirds{
        position: absolute;
        top: 30vh;
        left: 15vw;
        height: 100vh;
        width: 70vw;
        /* background-color: #EB1484; */
        display: grid;
    }
    .hometophotbirdstitle{
        /* position: absolute;
        top: 0vh; */
        z-index: 10;
        font-size: 1.5rem;
        color: #000000;
        font-weight: bold;
        /* background-color: #C91CC3; */
        text-align: left;
    }
    .homehotbirdscard{
        /* position: absolute;
        top: 8vh; */
        background-color: white;
        height: 40vh;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .homehotbirdscard2{
        /* position: absolute;
        top: 8vh; */
        background-color: white;
        min-height: 40vh;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .homehotbirdcardcards1{
        background-color: #FFFFFF;
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards2{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards3{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards4{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbidsimage{
        /* position: absolute; */
        margin-top: 2vh;
        height: 30vh;
        width: 5vw;
    }
    .hotbidsimagepic{
        height: 30vh;
        width: 13vw;
        border-radius: 20px;
        margin-left: 1vw;
        object-fit: cover;
    }
    .homehotbidstext{
        width: auto;
        margin-left: 1vw;
        font-weight: bold;
        font-size: 0.8rem;
    }
    .hotbidcardbottomsection{
        margin-left: 1vw;
        width: 13vw;
        display: flex;
        justify-content: space-between;
    }
    .hotbiddatandtext{
        /* position: absolute; */
        display: flex;
    }
    .hotbiddata{
        font-weight: bold;
    }
    .hotbidicon{
        /* position: absolute; */
        right: 0px;
    }
    .logoimg{
        height: 1.6vh;
        width: 0.8vw;
        /* background-color: #C81CC5; */
    }
    .loadbtndiv{
        position: absolute;
        top: 215vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
    .loadbutton{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: black;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .buttonc{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .loadbtndivd{
        position: absolute;
        top: 180vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
     .loadbtndivc{
        position: absolute;
        top: 170vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
    .loadbutton{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: black;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .buttond{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .buttonc{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .footerprofilepg{
        position: absolute;
        width: 100%;
        top: 180vh;
    }
}
@media(min-width:869px) and (max-width:1024px){
    .profilepage{
        /* background-color: #DA18A3; */
        height: 170vh;
    }
    .navprofile{
        position: fixed;
        width: 100%;
        z-index: 998;
    }
    .navpromobile{
        display: none;
    }
    .profiletopbackground{
        /* position: absolute;
        top: 0px; */
        height: 40vh;
        width: 100%;
    }
    .profilebgimg{
        height: 40vh;
        width: 100%;
        object-fit: cover;
    }
    .profiletopimage{
        height: 20vh;
        width: 100%;
    }
    .profileimageimg{
        position: absolute;
        top: 20vh;
        left: 42.5vw;
        height: 15vw;
        width: 15vw;
        border-radius: 7.5vw;
        object-fit: cover;
        border: 4px solid black;
    }
    .profileoptions{
        display: flex;
        justify-content: space-between;
    }
    .profilesearch{
        margin-top: 30vh;
        margin-left: 15vw;
        height: 5vh;
        width: 50%;
        display: flex;
        /* background-color: aqua; */
        border-radius: 5px;
        border:2px solid #E3E1E3;
    }
    .profilerecently{
        margin-top: 30vh;
        margin-right: 15vw;
        height: 5vh;
        width: 10%;
        display: flex;
        /* background-color: aqua; */
        border-radius: 5px;
        border:2px solid #E3E1E3;
    }
    .searchicon{
        height: 3vh;
        margin-top: 1vh;
        width: 1.5vw;
    }
    .searchbarexplore{
        margin-top: 1vh;
        height: 3vh;
        width: 100%;
        border: none;
    }
    .hometophotbirds{
        position: absolute;
        top: 60vh;
        left: 15vw;
        height: 100vh;
        width: 70vw;
        /* background-color: #EB1484; */
        display: grid;
    }
    .hometophotbirdstitle{
        /* position: absolute;
        top: 0vh; */
        z-index: 10;
        font-size: 1.5rem;
        color: #000000;
        font-weight: bold;
        /* background-color: #C91CC3; */
        text-align: left;
    }
    .homehotbirdscard{
        /* position: absolute;
        top: 8vh; */
        background-color: white;
        height: 40vh;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .homehotbirdscard2{
        /* position: absolute;
        top: 8vh; */
        background-color: white;
        height: 40vh;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .homehotbirdcardcards1{
        background-color: #FFFFFF;
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards2{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards3{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards4{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbidsimage{
        /* position: absolute; */
        margin-top: 2vh;
        height: 30vh;
        width: 5vw;
    }
    .hotbidsimagepic{
        height: 30vh;
        width: 13vw;
        border-radius: 20px;
        margin-left: 1vw;
        object-fit: cover;
    }
    .homehotbidstext{
        width: auto;
        margin-left: 1vw;
        font-weight: bold;
        font-size: 0.8rem;
    }
    .hotbidcardbottomsection{
        margin-left: 1vw;
        width: 13vw;
        display: flex;
        justify-content: space-between;
    }
    .hotbiddatandtext{
        /* position: absolute; */
        display: flex;
    }
    .hotbiddata{
        font-weight: bold;
    }
    .hotbidicon{
        /* position: absolute; */
        right: 0px;
    }
    .logoimg{
        height: 1.6vh;
        width: 0.8vw;
        /* background-color: #C81CC5; */
    }
    .loadbtndiv{
        position: absolute;
        top: 215vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
    .loadbutton{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: black;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .buttonc{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .loadbtndivd{
        position: absolute;
        top: 180vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
     .loadbtndivc{
        position: absolute;
        top: 170vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
    .loadbutton{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: black;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .buttond{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .buttonc{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .footerprofilepg{
        position: absolute;
        width: 100%;
        top: 180vh;
    }
}
@media(min-width:1025px){
    .profilepage{
        /* background-color: #DA18A3; */
        height: 170vh;
    }
    .navprofile{
        position: fixed;
        width: 100%;
        z-index: 998;
    }
    .navpromobile{
        display: none;
    }
    .profiletopbackground{
        /* position: absolute;
        top: 0px; */
        height: 40vh;
        width: 100%;
    }
    .profilebgimg{
        height: 40vh;
        width: 100%;
        object-fit: cover;
    }
    .profiletopimage{
        height: 20vh;
        width: 100%;
    }
    .profileimageimg{
        position: absolute;
        top: 20vh;
        left: 42.5vw;
        height: 15vw;
        width: 15vw;
        border-radius: 7.5vw;
        object-fit: cover;
        border: 4px solid black;
    }
    .profileoptions{
        display: flex;
        justify-content: space-between;
    }
    .profilesearch{
        margin-top: 30vh;
        margin-left: 15vw;
        height: 5vh;
        width: 50%;
        display: flex;
        /* background-color: aqua; */
        border-radius: 5px;
        border:2px solid #E3E1E3;
    }
    .profilerecently{
        margin-top: 30vh;
        margin-right: 15vw;
        height: 5vh;
        width: 10%;
        display: flex;
        /* background-color: aqua; */
        border-radius: 5px;
        border:2px solid #E3E1E3;
    }
    .searchicon{
        height: 3vh;
        margin-top: 1vh;
        width: 1.5vw;
    }
    .searchbarexplore{
        margin-top: 1vh;
        height: 3vh;
        width: 100%;
        border: none;
    }
    .hometophotbirds{
        position: absolute;
        top: 60vh;
        left: 15vw;
        height: 100vh;
        width: 70vw;
        /* background-color: #EB1484; */
        display: grid;
    }
    .hometophotbirdstitle{
        /* position: absolute;
        top: 0vh; */
        z-index: 10;
        font-size: 1.5rem;
        color: #000000;
        font-weight: bold;
        /* background-color: #C91CC3; */
        text-align: left;
    }
    .homehotbirdscard{
        /* position: absolute;
        top: 8vh; */
        background-color: white;
        height: 40vh;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .homehotbirdscard2{
        /* position: absolute;
        top: 8vh; */
        background-color: white;
        height: 40vh;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .homehotbirdcardcards1{
        background-color: #FFFFFF;
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards2{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards3{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards4{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbidsimage{
        /* position: absolute; */
        margin-top: 2vh;
        height: 30vh;
        width: 5vw;
    }
    .hotbidsimagepic{
        height: 30vh;
        width: 13vw;
        border-radius: 20px;
        margin-left: 1vw;
        object-fit: cover;
    }
    .homehotbidstext{
        width: auto;
        margin-left: 1vw;
        font-weight: bold;
        font-size: 1rem;
    }
    .hotbidcardbottomsection{
        margin-left: 1vw;
        width: 13vw;
        display: flex;
        justify-content: space-between;
    }
    .hotbiddatandtext{
        /* position: absolute; */
        display: flex;
    }
    .hotbiddata{
        font-weight: bold;
    }
    .hotbidicon{
        /* position: absolute; */
        right: 0px;
    }
    .logoimg{
        height: 1.6vh;
        width: 0.8vw;
        /* background-color: #C81CC5; */
    }
    .loadbtndiv{
        position: absolute;
        top: 215vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
    .loadbutton{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: black;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .buttonc{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .loadbtndivd{
        position: absolute;
        top: 180vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
     .loadbtndivc{
        position: absolute;
        top: 170vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
    .loadbutton{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: black;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .buttond{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .buttonc{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .footerprofilepg{
        position: absolute;
        width: 100%;
        top: 180vh;
    }
}

