@media(min-width:480px) and (max-width:868px){
    .itempage{
        height: auto;
        width:100%;
        display: grid;
        /* background-color: aqua; */
    }
    .navitem{
        position: fixed;
        width: 100%;
        z-index: 998;
        display: none;
    }
    .itemleft{
        /* position: absolute; */
        /* margin-top: 20vh; */
        height: 80vh;
        margin-top: 5vh;
        width: 100%;
        border-right:2px solid #E1EAE3;
    }
    .leftimgdiv{
        /* position: absolute; */
        margin-top: 15vh;
        margin-left: 15%;
        height: 60vh;
        width: 70%;
    }
    .leftimg{
        height: 60vh;
        width: 100%;
        border-radius: 20px;
    }
    .itemright{
        /* position: absolute; */
        /* margin-top: 20vh; */
        height: 90vh;
        /* margin-top: 10vh; */
        width: 100%;
        /* background-color: aquamarine; */
    }
    .itemrightall{
        width: 90%;
        height: 80vh;
        margin-left: 5%;
        display: grid;
    }
    .itemrighttitle{
        display: flex;
    }
    .itemtitle{
        margin-top: 2vh;
        font-weight: bold;
        font-size: 2rem;
    }
    .itemrighticondiv{
        height: 6vh;
        width: 8vw;
        /* background-color: #DA18A3; */
        margin-top: 3vh;
        margin-left: 1vw;
        border-radius: 20px;
        border:2px solid #CAC9CD;
    }
    .iconheart{
        margin-top: 1.2vh;
        min-height: 1.8vh;
        min-width: 1vw;
        margin-left: 0.8vw;
    }
    .profilecontnt{
        display: flex;
        width: 25%;
        margin-top: -4vh;
        /* background-color: #C91CC3; */
        justify-content: space-evenly;
    }
    .profiledata{
        display: flex;
        width: 35%;
        height: 5vh;
        margin-top: -4vh;
        /* background-color: #DA18A3; */
        justify-content: space-between;
    }
    .prodata{
        font-weight: bold;
        min-width: 15vw;
        margin-left: 1vw;
    }
    .data20{
        min-width: 20vw;
    }
    .dot{
        height: 0.4vw;
        width: 0.4vw;
        border-radius: 0.2vw;
        margin-top: 1.2vh;
        background-color: #CAC9CD;
    }
    .creator{
        height: 5vh;
        /* background-color: #C91CC3; */
    }
    .miaimgdiv{
        height: 5vw;
        width: 5vw;
        /* background-color: aqua; */
    }
    .miatextdiv{
        height: 5vw;
        width: 100%;
        vertical-align: middle;
        /* background-color: aquamarine; */
    }
    .miatext{
        height: auto;
        width: 100%;
        margin-top: 1vh;
        margin-left: 1vw;
        vertical-align: middle;
        font-weight: 600;
        font-size: 1rem;
        /* background-color: wheat; */
    }
    .miaimg{
        height: 5vw;
        width: 5vw;
        border-radius: 2.5vw;
        object-fit: contain;
    }
    .itembutton{
        width: 80%;
        justify-content: space-between;
        display: flex;
        /* background-color: aqua; */
    }
    .doh{
        display: flex;
        width: 50%;
        justify-content: space-between;
    }
    .details:hover{
        text-decoration: underline;
    }
    .dohbasediv{
        height: 0.25vh;
        width: 50%;
        border-radius: 10px;
        background-color: #CAC9CD;;
    }
    .dohtext{
        width: 80%;
        text-align: justify;
        margin-top: 3vh;
    }
    
    .buttondivk{
        border-radius: 10px;
        outline: none;
        height: 7vh;
        width: 15vw;
     } 
     .loadbuttonk{
        height: 7vh;
        width: 25vw;
        border: 2px solid #DA18A3;
        background: linear-gradient(101.12deg, #EB1484 27.35%, #C91CC3 99.99%, #C81CC5 100%, #C81CC5 100%);
        color: black;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
     .loadbuttonl{
        min-height: 7vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: black;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .buttonk{
        text-decoration: none;
        color: #FFFFFF;
        font-size: 1rem;
        /* background: linear-gradient(101.12deg, #EB1484 27.35%, #C91CC3 99.99%, #C81CC5 100%, #C81CC5 100%); */
        font-weight: 600;
        outline: none;
    }
    .buttonl{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .footeritempg{
        /* position: absolute; */
        width: 100%;
        /* top: 100vh; */
    }
}
@media(min-width:869px) and (max-width:1024px){
    .itempage{
        height: 150vh;
        width:100%;
        display: flex;
        /* background-color: aqua; */
    }
    .navitem{
        position: fixed;
        width: 100%;
        z-index: 998;
    }
    .navitemmobile{
        display: none;
    }
    .itemleft{
        /* position: absolute; */
        /* margin-top: 20vh; */
        height: 90vh;
        margin-top: 10vh;
        width: 50%;
        border-right:2px solid #E1EAE3;
    }
    .leftimgdiv{
        /* position: absolute; */
        margin-top: 15vh;
        margin-left: 20%;
        height: 60vh;
        width: 70%;
    }
    .leftimg{
        height: 60vh;
        width: 100%;
        border-radius: 20px;
    }
    .itemright{
        /* position: absolute; */
        /* margin-top: 20vh; */
        height: 90vh;
        margin-top: 10vh;
        width: 50%;
        /* background-color: aquamarine; */
    }
    .itemrightall{
        width: 90%;
        height: 80vh;
        margin-left: 5%;
        display: grid;
    }
    .itemrighttitle{
        display: flex;
    }
    .itemtitle{
        margin-top: 2vh;
        font-weight: bold;
        font-size: 2rem;
    }
    .itemrighticondiv{
        height: 4vh;
        width: 4vw;
        /* background-color: #DA18A3; */
        margin-top: 3vh;
        margin-left: 1vw;
        border-radius: 20px;
        border:2px solid #CAC9CD;
    }
    .iconheart{
        margin-top: 1vh;
        height: 1.8vh;
        width: 1vw;
        margin-left: 0.8vw;
    }
    .profilecontnt{
        display: flex;
        width: 25%;
        margin-top: -4vh;
        /* background-color: #C91CC3; */
        justify-content: space-evenly;
    }
    .profiledata{
        display: flex;
        width: 35%;
        height: 5vh;
        margin-top: -4vh;
        /* background-color: #DA18A3; */
        justify-content: space-between;
    }
    .prodata{
        font-weight: bold;
        min-width: 10vw;
        margin-left: 1vw;
    }
    .data20{
        min-width: 40vw;
    }
    .dot{
        height: 0.4vw;
        width: 0.4vw;
        border-radius: 0.2vw;
        margin-top: 1.2vh;

        background-color: #CAC9CD;
    }
    .creator{
        height: 5vh;
        /* background-color: #C91CC3; */
    }
    .miaimgdiv{
        height: 5vw;
        width: 5vw;
        /* background-color: aqua; */
    }
    .miatextdiv{
        min-height: 5vh;
        min-width: 10vw;
        vertical-align: middle;
        /* background-color: aquamarine; */
    }
    .miatext{
        min-height: 5vh;
        min-width: 10vw;
        margin-top: 1.5vh;
        vertical-align: middle;
        font-weight: 600;
        /* background-color: wheat; */
    }
    .miaimg{
        height: 5vw;
        width: 5vw;
        border-radius: 2.5vw;
        object-fit: contain;
    }
    .details:hover{
        text-decoration: underline;
    }
    .itembutton{
        width: 80%;
        justify-content: space-between;
        display: flex;
        /* background-color: aqua; */
    }
    .doh{
        display: flex;
        width: 50%;
        justify-content: space-between;
    }
    
    .dohbasediv{
        height: 0.25vh;
        width: 50%;
        border-radius: 10px;
        background-color: #CAC9CD;;
    }
    .dohtext{
        width: 80%;
        text-align: justify;
        margin-top: 3vh;
    }
    
    .buttondivk{
        border-radius: 10px;
        outline: none;
     } 
     .loadbuttonk{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background: linear-gradient(101.12deg, #EB1484 27.35%, #C91CC3 99.99%, #C81CC5 100%, #C81CC5 100%);
        color: black;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
     .loadbuttonl{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: black;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .buttonk{
        text-decoration: none;
        color: #FFFFFF;
        font-size: 1rem;
        /* background: linear-gradient(101.12deg, #EB1484 27.35%, #C91CC3 99.99%, #C81CC5 100%, #C81CC5 100%); */
        font-weight: 600;
        outline: none;
    }
    .buttonl{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .footeritempg{
        position: absolute;
        width: 100%;
        top: 100vh;
    }
}
@media(min-width:1025px){
    .itempage{
        height: 150vh;
        width:100%;
        display: flex;
        /* background-color: aqua; */
    }
    .navitem{
        position: fixed;
        width: 100%;
        z-index: 998;
    }
    .navitemmobile{
        display: none;
    }
    .itemleft{
        /* position: absolute; */
        /* margin-top: 20vh; */
        height: 90vh;
        margin-top: 10vh;
        width: 50%;
        border-right:2px solid #E1EAE3;
    }
    .leftimgdiv{
        /* position: absolute; */
        margin-top: 15vh;
        margin-left: 20%;
        height: 60vh;
        width: 70%;
    }
    .leftimg{
        height: 60vh;
        width: 100%;
        border-radius: 20px;
    }
    .itemright{
        /* position: absolute; */
        /* margin-top: 20vh; */
        height: 90vh;
        margin-top: 10vh;
        width: 50%;
        /* background-color: aquamarine; */
    }
    .itemrightall{
        width: 90%;
        height: 80vh;
        margin-left: 5%;
        display: grid;
    }
    .itemrighttitle{
        display: flex;
    }
    .itemtitle{
        margin-top: 2vh;
        font-weight: bold;
        font-size: 2rem;
    }
    .itemrighticondiv{
        height: 4vh;
        width: 4vw;
        /* background-color: #DA18A3; */
        margin-top: 3vh;
        margin-left: 1vw;
        border-radius: 20px;
        border:2px solid #CAC9CD;
    }
    .iconheart{
        margin-top: 1vh;
        height: 1.8vh;
        width: 1vw;
        margin-left: 0.8vw;
    }
    .profilecontnt{
        display: flex;
        width: 25%;
        margin-top: -4vh;
        /* background-color: #C91CC3; */
        justify-content: space-evenly;
    }
    .profiledata{
        display: flex;
        width: 35%;
        height: 5vh;
        margin-top: -4vh;
        /* background-color: #DA18A3; */
        justify-content: space-between;
    }
    .prodata{
        font-weight: bold;
        min-width: 10vw;
        margin-left: 1vw;
    }
    .data20{
        min-width: 20vw;
    }
    .dot{
        height: 0.4vw;
        width: 0.4vw;
        border-radius: 0.2vw;
        margin-top: 1.2vh;
        margin-right: 2vw;
        background-color: #CAC9CD;
    }
    .creator{
        height: 5vh;
        /* background-color: #C91CC3; */
    }
    .miaimgdiv{
        height: 5vw;
        width: 5vw;
        /* background-color: aqua; */
    }
    .miatextdiv{
        height: 5vw;
        min-width: 5vw;
        vertical-align: middle;
        /* background-color: aquamarine; */
    }
    .miatext{
        height: auto;
        min-width: 15vw;
        margin-left: 1vw;
        margin-top: 3.5vh;
        vertical-align: middle;
        font-weight: 600;
        /* background-color: wheat; */
    }
    .miaimg{
        height: 5vw;
        width: 5vw;
        border-radius: 2.5vw;
        object-fit: contain;
    }
    .itembutton{
        width: 80%;
        justify-content: space-between;
        display: flex;
        /* background-color: aqua; */
    }
    .doh{
        display: flex;
        width: 50%;
        justify-content: space-between;
    }
    .details:hover{
        text-decoration: underline;
    }
    .dohbasediv{
        height: 0.25vh;
        width: 50%;
        border-radius: 10px;
        background-color: #CAC9CD;;
    }
    .dohtext{
        width: 80%;
        text-align: justify;
        margin-top: 3vh;
    }
    .loadbuttonk{
        height: 5vh;
        width: 15vw;
        border: none;
        outline: none;
        background-color: transparent;
        color: black;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .buttondivk{
        border-radius: 10px;
        outline: none;
     } 
     .loadbuttonk{
        height: 5vh;
        width: 15vw;
        border: 2px solid #DA18A3;
        background: linear-gradient(101.12deg, #EB1484 27.35%, #C91CC3 99.99%, #C81CC5 100%, #C81CC5 100%);
        color: #FFFFFF;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
     .loadbuttonl{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: #DA18A3;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .buttonk{
        text-decoration: none;
        color: #FFFFFF;
        font-size: 1rem;
        /* background: linear-gradient(101.12deg, #EB1484 27.35%, #C91CC3 99.99%, #C81CC5 100%, #C81CC5 100%); */
        font-weight: 600;
        outline: none;
    }
    .buttonl{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .footeritempg{
        position: absolute;
        width: 100%;
        top: 100vh;
    }
    .checktitle{
        font-size: 1.5rem;;
        font-weight: bold;
        width: 100%;
        margin-top: 2vh;
        text-align: center;
    }
    .checknext{
        height: 0.2vh;
        width: 100%;
        margin-top: 2vh;
        background-color: #CAC9CD;
    }
    .subtitl{
        display: flex;
        margin-top: 2vh;
        justify-content: space-between;
    }
    .modalitem{
        font-weight: 600;
        margin-top: 2vh;
    }
    .modalsubtotal{
        font-weight: 600;
        margin-top: 2vh;
    }
    .modalttldata{
        display: flex;
    }
    .modaldatatext{
        font-weight: 600;
        margin-left: 0.5vw;
    }
    .modaltotal{
        font-weight: 600;
    }
    .modaltotaldata{
        display: flex;
        margin-top: 2vh;
        justify-content: space-between;
    }
    .modaltotalnext{
        height: 0.2vh;
        width: 100%;
        margin-top: 3vh;
        background-color: #CAC9CD;
    }
    .imgnexttext{
        display: grid;
        margin-top: 4vh;
        margin-left: 1vw;
    }
    .modalimgndtxt{
        display: flex;
    }
    .modal3rd{
        display: flex;
        margin-top: 2vh;
        justify-content: space-between;
    }
    .modalabstract{
        margin-top: -4vh;

    }
    .modalimg{
        height: 15vh;
        width: 10vw;
    }
    .modalimgpic{
        height: 15vh;
        width: 10vw;
        object-fit: fill;
    }
    .modalbuttons{
        display: flex;
        margin-top: 3vh;
        justify-content: space-around;
    }
    .modalcheckout{
        height: 4vh;
        width: 7vw;
        border-radius: 5px;
        border: none;
        color: #FFFFFF;
        background: linear-gradient(101.12deg, #EB1484 27.35%, #C91CC3 99.99%, #C81CC5 100%, #C81CC5 100%);
    }
    .modalcancel{
        height: 4vh;
        width: 7vw;
        border-radius: 5px;
        border:1px solid#DA18A3;
        background-color: transparent;
        color: #DA18A3;
    }
}

