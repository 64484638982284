
@media(min-width:480px) and (max-width:868px){
    .navbarhome{
        position: fixed;
        width: 100%;
        z-index: 998;
        display: none;
    }
    .homepage{
        background-color: #FFFFFF;
        height: 250vh;
        width: 100%;
    }
    .hometopcard{
        position: absolute;
        width: 70vw;
        height: 40vh;
        left: 15vw;
        top: 20vh;
        border-radius: 25px;
        background: linear-gradient(101.12deg, #EB1484 27.35%, #C91CC3 99.99%, #C81CC5 100%, #C81CC5 100%);
    }
    .hometopellipse{
        position: absolute;
        width: 20vw;
        height: 20vw;
        left: -10vw;
        top:-18vh;
        border-radius: 400px;
        background: rgba(255, 255, 255, 0.2);
    }
    .homebottomellipse{
        position: absolute;
        width: 20vw;
        height: 20vw;
        right: -5vw;
        top:12vh;
        border-radius: 400px;
        background: rgba(255, 255, 255, 0.2);
    }
    .hometopcardtext1{
        position: absolute;
        top: 10vh;
        left: 5vw;
        font-size: 2.5rem;
        color: #FFFFFF;
    }
    .hometopcardtext2{
        position: absolute;
        top: 18vh;
        left: 5vw;
        font-size: 2.5rem;
        color: #FFFFFF;
    }
    .hometopsellercardi{
        position: absolute;
        top: 70vh;
        z-index: 12;
        left: 15vw;
        height: 35vh;
        width: 70vw;
        /* background-color: aqua; */
    }
    .hometopsellertitle{
        position: absolute;
        top: 0px;
        font-size: 1.5rem;
        font-weight: bold;
    }
    .hometopsellercards{
        position: absolute;
        top: 8vh;
        /* background-color: #C91CC3; */
        height: 25vh;
        width: 70vw;
        display: flex;
        justify-content: space-around;
    }
    .hometopsellerscardscards1{
        background-color: #FFFFFF;
        min-height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .ticksellerdiv{
        display: flex;
    }
    .tickdiv{
        height: 1.2vw;
        width: 1.2vw;
        border-radius: 0.6vw;
        margin-top: 9vh;
        margin-left: -1vw;
        z-index: 15;
        background-color: #00B247;
    }
    .tickimg{
        height: 1vw;
        width: 1vw;
    }
    .topsellermarking{
        width: 2vw;
        height: 2vw;
        border-radius: 1vw;
        background-color: #DA18A3;
        margin-top: 1vh;
        margin-left: 0.5vw;
        color: #FFFFFF;
    }
    .hometopsellercardimage{
        margin-top: 2vh;
        margin-left: 2.5vw;
        height: 5vw;
        width: 5vw;
        border-radius: 2.5vw;
        z-index: 8;
        background-color: aquamarine;
    }
    .hometopsellercardtext{
        margin-top: 1vh;
        width: 100%;
        text-align: center;
        z-index: 8;
        font-size: 1rem;
        font-weight: bold;
    }
    .hometopsellercarddatandtext{
        width: 40%;
        margin-left: 30%;
        display: flex;
        justify-content: space-around;
    }

    .hometopsellercarddata{
        z-index: 8;
        font-size: 0.8rem;
        font-weight: bold;
    }
    .hometopsellercarddatatext{
        z-index: 8;
        font-size: 0.8rem;
    }
    .hometopsellerscardscards2{
        background-color: #FFFFFF;
        height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .hometopsellerscardscards3{
        background-color: #FFFFFF;
        height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .hometopsellerscardscards4{
        background-color: #FFFFFF;
        height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .hometopsellerscardscards5{
        background-color: #FFFFFF;
        min-height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .hometopsellerimg{
        height: 5vw;
        width: 5vw;
        border-radius: 2.5vw;
        object-fit: cover;
    }
    .hometophotbirds1{
        position: absolute;
        top: 110vh;
        left: 15vw;
        height: 100vh;
        width: 70vw;
        /* background-color: #EB1484; */
        display: grid;
    }
    .hometophotbirdstitle{
        /* position: absolute;
        top: 0vh; */
        z-index: 10;
        font-size: 1.5rem;
        color: #000000;
        font-weight: bold;
        /* background-color: #C91CC3; */
        text-align: left;
    }
    .homehotbirdscard{
        /* position: absolute;
        top: 8vh; */
        background-color: white;
        height: 40vh;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .homehotbirdscard2{
        /* position: absolute;
        top: 8vh; */
        background-color: white;
        height: 40vh;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .homehotbirdcardcards1{
        background-color: #FFFFFF;
        min-height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards2{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards3{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards4{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbidsimage{
        /* position: absolute; */
        margin-top: 2vh;
        height: 30vh;
        width: 5vw;
    }
    .hotbidsimagepic{
        height: 30vh;
        width: 13vw;
        border-radius: 20px;
        margin-left: 1vw;
        object-fit: cover;
    }
    .homehotbidstext1{
        width: auto;
        text-align: left;
        margin-left: 1vw;
        font-weight: bold;
        font-size: 0.8rem;
    }
    .hotbidcardbottomsection{
        margin-left: 1vw;
        width: 13vw;
        display: flex;
        justify-content: space-between;
    }
    .hotbiddatandtext{
        /* position: absolute; */
        display: flex;
    }
    .hotbiddata{
        font-weight: bold;
    }
    .hotbidicon{
        /* position: absolute; */
        right: 0px;
    }

    .logoimghme{
        height: 0.8vh;
        width: 0.4vw;
        /* background-color: #C81CC5; */
    }
    .loadbtndiv{
        position: absolute;
        top: 215vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
    .loadbutton1{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: #DA18A3;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .loadbtndivb{
        position: absolute;
        top: 220vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
    .loadbutton{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: black;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .buttonb{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .footerhomepg{
        position: absolute;
        width: 100%;
        top: 235vh;
    }
}
@media(min-width:869px) and (max-width:1024px){
    .navbarhome{
        position: fixed;
        width: 100%;
        z-index: 998;
    }
    .navhmemobile{
        display: none;
    }
    .homepage{
        background-color: #FFFFFF;
        height: 250vh;
        width: 100%;
    }
    .hometopcard{
        position: absolute;
        width: 70vw;
        height: 40vh;
        left: 15vw;
        top: 20vh;
        border-radius: 25px;
        background: linear-gradient(101.12deg, #EB1484 27.35%, #C91CC3 99.99%, #C81CC5 100%, #C81CC5 100%);
    }
    .hometopellipse{
        position: absolute;
        width: 20vw;
        height: 20vw;
        left: -10vw;
        top:-18vh;
        border-radius: 400px;
        background: rgba(255, 255, 255, 0.2);
    }
    .homebottomellipse{
        position: absolute;
        width: 20vw;
        height: 20vw;
        right: -5vw;
        top:12vh;
        border-radius: 400px;
        background: rgba(255, 255, 255, 0.2);
    }
    .hometopcardtext1{
        position: absolute;
        top: 10vh;
        left: 5vw;
        font-size: 2.5rem;
        color: #FFFFFF;
    }
    .hometopcardtext2{
        position: absolute;
        top: 18vh;
        left: 5vw;
        font-size: 2.5rem;
        color: #FFFFFF;
    }
    .hometopsellercardi{
        position: absolute;
        top: 70vh;
        z-index: 12;
        left: 15vw;
        height: 35vh;
        width: 70vw;
        /* background-color: aqua; */
    }
    .hometopsellertitle{
        position: absolute;
        top: 0px;
        font-size: 1.5rem;
        font-weight: bold;
    }
    .hometopsellercards{
        position: absolute;
        top: 8vh;
        /* background-color: #C91CC3; */
        height: 25vh;
        width: 70vw;
        display: flex;
        justify-content: space-around;
    }
    .hometopsellerscardscards1{
        background-color: #FFFFFF;
        min-height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .ticksellerdiv{
        display: flex;
    }
    .tickdiv{
        height: 1.2vw;
        width: 1.2vw;
        border-radius: 0.6vw;
        margin-top: 9vh;
        margin-left: -1vw;
        z-index: 15;
        background-color: #00B247;
    }
    .tickimg{
        height: 1vw;
        width: 1vw;
    }
    .topsellermarking{
        width: 2vw;
        height: 2vw;
        border-radius: 1vw;
        background-color: #DA18A3;
        margin-top: 1vh;
        margin-left: 0.5vw;
        color: #FFFFFF;
    }
    .hometopsellercardimage{
        margin-top: 2vh;
        margin-left: 2.5vw;
        height: 5vw;
        width: 5vw;
        border-radius: 2.5vw;
        z-index: 8;
        background-color: aquamarine;
    }
    .hometopsellercardtext{
        margin-top: 1vh;
        width: 100%;
        text-align: center;
        z-index: 8;
        font-size: 1rem;
        font-weight: bold;
    }
    .hometopsellercarddatandtext{
        width: 40%;
        margin-left: 30%;
        display: flex;
        justify-content: space-around;
    }

    .hometopsellercarddata{
        z-index: 8;
        font-size: 0.8rem;
        font-weight: bold;
    }
    .hometopsellercarddatatext{
        z-index: 8;
        font-size: 0.8rem;
    }
    .hometopsellerscardscards2{
        background-color: #FFFFFF;
        height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .hometopsellerscardscards3{
        background-color: #FFFFFF;
        height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .hometopsellerscardscards4{
        background-color: #FFFFFF;
        height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .hometopsellerscardscards5{
        background-color: #FFFFFF;
        min-height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .hometopsellerimg{
        height: 5vw;
        width: 5vw;
        border-radius: 2.5vw;
        object-fit: cover;
    }
    .hometophotbirds1{
        position: absolute;
        top: 110vh;
        left: 15vw;
        height: 100vh;
        width: 70vw;
        /* background-color: #EB1484; */
        display: grid;
    }
    .hometophotbirdstitle{
        /* position: absolute;
        top: 0vh; */
        z-index: 10;
        font-size: 1.5rem;
        color: #000000;
        font-weight: bold;
        /* background-color: #C91CC3; */
        text-align: left;
    }
    .homehotbirdscard{
        /* position: absolute;
        top: 8vh; */
        background-color: white;
        height: 40vh;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .homehotbirdscard2{
        /* position: absolute;
        top: 8vh; */
        background-color: white;
        height: 40vh;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .homehotbirdcardcards1{
        background-color: #FFFFFF;
        min-height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards2{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards3{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards4{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbidsimage{
        /* position: absolute; */
        margin-top: 2vh;
        height: 30vh;
        width: 5vw;
    }
    .hotbidsimagepic{
        height: 30vh;
        width: 13vw;
        border-radius: 20px;
        margin-left: 1vw;
        object-fit: cover;
    }
    .homehotbidstext1{
        width: auto;
        text-align: left;
        margin-left: 1vw;
        font-weight: bold;
        font-size: 0.8rem;
    }
    .hotbidcardbottomsection{
        margin-left: 1vw;
        width: 13vw;
        display: flex;
        justify-content: space-between;
    }
    .hotbiddatandtext{
        /* position: absolute; */
        display: flex;
    }
    .hotbiddata{
        font-weight: bold;
    }
    .hotbidicon{
        /* position: absolute; */
        right: 0px;
    }
    .logoimghme{
        height: 1.6vh;
        width: 0.8vw;
        /* background-color: #C81CC5; */
    }
    .loadbtndiv{
        position: absolute;
        top: 215vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
    .loadbutton1{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: #DA18A3;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .loadbtndivb{
        position: absolute;
        top: 220vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
    .loadbutton{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: black;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .buttonb{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .footerhomepg{
        position: absolute;
        width: 100%;
        top: 235vh;
    }
}
@media(min-width:1025px){
    .navbarhome{
        position: fixed;
        width: 100%;
        z-index: 998;
    }
    .navhmemobile{
        display: none;
    }
    .homepage{
        background-color: #FFFFFF;
        height: 250vh;
        width: 100%;
    }
    .hometopcard{
        position: absolute;
        width: 70vw;
        height: 40vh;
        left: 15vw;
        top: 20vh;
        border-radius: 25px;
        background: linear-gradient(101.12deg, #EB1484 27.35%, #C91CC3 99.99%, #C81CC5 100%, #C81CC5 100%);
    }
    .hometopellipse{
        position: absolute;
        width: 20vw;
        height: 20vw;
        left: -10vw;
        top:-18vh;
        border-radius: 400px;
        background: rgba(255, 255, 255, 0.2);
    }
    .homebottomellipse{
        position: absolute;
        width: 20vw;
        height: 20vw;
        right: -5vw;
        top:12vh;
        border-radius: 400px;
        background: rgba(255, 255, 255, 0.2);
    }
    .hometopcardtext1{
        position: absolute;
        top: 10vh;
        left: 5vw;
        font-size: 2.5rem;
        color: #FFFFFF;
    }
    .hometopcardtext2{
        position: absolute;
        top: 18vh;
        left: 5vw;
        font-size: 2.5rem;
        color: #FFFFFF;
    }
    .hometopsellercardi{
        position: absolute;
        top: 70vh;
        z-index: 12;
        left: 15vw;
        height: 35vh;
        width: 70vw;
        /* background-color: aqua; */
    }
    .hometopsellertitle{
        position: absolute;
        top: 0px;
        font-size: 1.5rem;
        font-weight: bold;
    }
    .hometopsellercards{
        position: absolute;
        top: 8vh;
        /* background-color: #C91CC3; */
        height: 25vh;
        width: 70vw;
        display: flex;
        justify-content: space-around;
    }
    .hometopsellerscardscards1{
        background-color: #FFFFFF;
        min-height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .ticksellerdiv{
        display: flex;
    }
    .tickdiv{
        height: 1.2vw;
        width: 1.2vw;
        border-radius: 0.6vw;
        margin-top: 9vh;
        margin-left: -1vw;
        z-index: 15;
        background-color: #00B247;
    }
    .tickimg{
        height: 1vw;
        width: 1vw;
    }
    .topsellermarking{
        width: 2vw;
        height: 2vw;
        border-radius: 1vw;
        background-color: #DA18A3;
        margin-top: 1vh;
        margin-left: 0.5vw;
        color: #FFFFFF;
    }
    .hometopsellercardimage{
        margin-top: 2vh;
        margin-left: 2.5vw;
        height: 5vw;
        width: 5vw;
        border-radius: 2.5vw;
        z-index: 8;
        background-color: aquamarine;
    }
    .hometopsellercardtext{
        margin-top: 1vh;
        width: 100%;
        text-align: center;
        z-index: 8;
        font-size: 1rem;
        font-weight: bold;
    }
    .hometopsellercarddatandtext{
        width: 40%;
        margin-left: 30%;
        display: flex;
        justify-content: space-around;
    }

    .hometopsellercarddata{
        z-index: 8;
        font-size: 0.8rem;
        font-weight: bold;
    }
    .hometopsellercarddatatext{
        z-index: 8;
        font-size: 0.8rem;
    }
    .hometopsellerscardscards2{
        background-color: #FFFFFF;
        height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .hometopsellerscardscards3{
        background-color: #FFFFFF;
        height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .hometopsellerscardscards4{
        background-color: #FFFFFF;
        height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .hometopsellerscardscards5{
        background-color: #FFFFFF;
        min-height: 25vh;
        width: 10vw;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid #E3E1E3;
    }
    .hometopsellerimg{
        height: 5vw;
        width: 5vw;
        border-radius: 2.5vw;
        object-fit: cover;
    }
    .hometophotbirds1{
        position: absolute;
        top: 110vh;
        left: 15vw;
        height: 100vh;
        width: 70vw;
        /* background-color: #EB1484; */
        display: grid;
    }
    .hometophotbirdstitle{
        /* position: absolute;
        top: 0vh; */
        z-index: 10;
        font-size: 1.5rem;
        color: #000000;
        font-weight: bold;
        /* background-color: #C91CC3; */
        text-align: left;
    }
    .homehotbirdscard{
        /* position: absolute;
        top: 8vh; */
        background-color: white;
        height: 40vh;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .homehotbirdscard2{
        /* position: absolute;
        top: 8vh; */
        background-color: white;
        height: 40vh;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .homehotbirdcardcards1{
        background-color: #FFFFFF;
        min-height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards2{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards3{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbirdcardcards4{
        height: 40vh;
        width: 15vw;
        border-radius: 20px;
        box-shadow: 1px 2px 1px 2px #CAC9CD;
        display: grid;
    }
    .homehotbidsimage{
        /* position: absolute; */
        margin-top: 2vh;
        height: 30vh;
        width: 5vw;
    }
    .hotbidsimagepic{
        height: 30vh;
        width: 13vw;
        border-radius: 20px;
        margin-left: 1vw;
        object-fit: cover;
    }
    .homehotbidstext1{
        width: auto;
        text-align: left;
        margin-left: 1vw;
        font-weight: bold;
        font-size: 0.8rem;
    }
    .hotbidcardbottomsection{
        margin-left: 1vw;
        width: 13vw;
        display: flex;
        justify-content: space-between;
    }
    .hotbiddatandtext{
        /* position: absolute; */
        display: flex;
    }
    .hotbiddata{
        font-weight: bold;
    }
    .hotbidicon{
        /* position: absolute; */
        right: 0px;
    }
    .logoimghme{
        height: 1.6vh;
        width: 0.8vw;
        /* background-color: #C81CC5; */
    }
    .loadbtndiv{
        position: absolute;
        top: 215vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
    .loadbutton1{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: #DA18A3;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .loadbtndivb{
        position: absolute;
        top: 220vh;
        min-height: 5vh;
        width: 100%;
        text-align: center;
        text-decoration: none;
        /* margin-left: 47.5vw; */
     } 
    .loadbutton{
        min-height: 5vh;
        min-width: 15vw;
        border: 2px solid #DA18A3;
        background-color: transparent;
        color: black;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }
    .buttonb{
        text-decoration: none;
        color: #DA18A3;
        font-weight: 600;
    }
    .footerhomepg{
        position: absolute;
        width: 100%;
        top: 235vh;
    }
}