@media(min-width:480px) and (max-width:868px){
    .footerpage{
        height: 45vh;
        width: 100%;
        background-color: #FFFFFF;
        border-top: 1px solid #888888;
    }
    .ftpg{
        background-color: #FFFFFF;
        width: 100%;
        height: 30vh;
        border-bottom: 1px solid #888888;
    }
    .footerpageinside{
        display: flex;
        /* background-color: antiquewhite; */
        width: 70%;
        margin-left: 15%;
        height: 30vh;
    }
    .footerleft{
        width: 40%;
        /* background-color: aqua; */
        display: grid;
    }
    .logo{
        height: 5vh;
        width: 10vw;
    }
    .logoimg{
        height: 5vh;
        width: 10vw;
    }
    .email{
        display: flex;
        margin-top: -4vh;
    }
    .emailnext{
        height: 5vh;
        width: 10vw;
        border:1px solid #888888;
        border-radius: 5px;
    }
    .emailbutton{
        height: 5vh;
        width: 8vw;
        border-radius: 10px;
        border: none;
        font-size: 0.8rem;
        color: white;
        background: linear-gradient(101.12deg, #EB1484 27.35%, #C91CC3 99.99%, #C81CC5 100%, #C81CC5 100%);
    }
    .footercenter{
        width: 30%;
        /* background-color: #C91CC3; */
        display: grid;
    }
    .graphique{
        font-size: 1.2rem;
        font-weight: 600;
    }
    .how{
        margin-top: -4vh;
    }
    .contact{
        margin-top: -5vh;
    }
    .footerright{
        width: 30%;
        /* background-color: #EB1484; */
        display: grid;
    }
    .support{
        font-size: 1.2rem;
        font-weight: 600;
    }
    .help{
        margin-top: 3vh;
    }
    .footerbottom{
        display: flex;
        width: 60%;
        margin-left: 15%;
        margin-top: 5vh;
        justify-content: space-between;
    }
    .graphiquebottom{
        font-weight: bold;
        font-size: 1rem;
    }
    .socialicons{
        display: flex;
    }
    .igdiv{
        height: 5vh;
        width: 2.5vw;
    }
    .igimg{
        height: 4vh;
        width: 2vw;
    }
    .twitterdiv{
        height: 5vh;
        width: 2.5vw;
    }
    .twitterimg{
        height: 4vh;
        width: 2vw;
    }
}

@media(min-width:869px) and (max-width:1024px){
    .footerpage{
        height: 45vh;
        width: 100%;
        background-color: #FFFFFF;
        border-top: 1px solid #888888;
    }
    .ftpg{
        background-color: #FFFFFF;
        width: 100%;
        height: 30vh;
        border-bottom: 1px solid #888888;
    }
    .footerpageinside{
        display: flex;
        /* background-color: antiquewhite; */
        width: 70%;
        margin-left: 15%;
        height: 30vh;
    }
    .footerleft{
        width: 40%;
        /* background-color: aqua; */
        display: grid;
    }
    .logo{
        height: 5vh;
        width: 10vw;
    }
    .logoimg{
        height: 5vh;
        width: 10vw;
    }
    .email{
        display: flex;
        margin-top: -4vh;
    }
    .emailnext{
        height: 5vh;
        width: 10vw;
        border:1px solid #888888;
        border-radius: 5px;
    }
    .emailbutton{
        height: 5vh;
        width: 8vw;
        border-radius: 10px;
        border: none;
        font-size: 0.8rem;
        color: white;
        background: linear-gradient(101.12deg, #EB1484 27.35%, #C91CC3 99.99%, #C81CC5 100%, #C81CC5 100%);
    }
    .footercenter{
        width: 30%;
        /* background-color: #C91CC3; */
        display: grid;
    }
    .graphique{
        font-size: 1.2rem;
        font-weight: 600;
    }
    .how{
        margin-top: -4vh;
    }
    .contact{
        margin-top: -5vh;
    }
    .footerright{
        width: 30%;
        /* background-color: #EB1484; */
        display: grid;
    }
    .support{
        font-size: 1.2rem;
        font-weight: 600;
    }
    .help{
        margin-top: 3vh;
    }
    .footerbottom{
        display: flex;
        width: 60%;
        margin-left: 15%;
        margin-top: 5vh;
        justify-content: space-between;
    }
    .graphiquebottom{
        font-weight: bold;
        font-size: 1rem;
    }
    .socialicons{
        display: flex;
    }
    .igdiv{
        height: 5vh;
        width: 2.5vw;
    }
    .igimg{
        height: 4vh;
        width: 2vw;
    }
    .twitterdiv{
        height: 5vh;
        width: 2.5vw;
    }
    .twitterimg{
        height: 4vh;
        width: 2vw;
    }
}

@media(min-width:1025px){
    .footerpage{
        height: 45vh;
        width: 100%;
        background-color: #FFFFFF;
        border-top: 1px solid #888888;
    }
    .ftpg{
        background-color: #FFFFFF;
        width: 100%;
        height: 30vh;
        border-bottom: 1px solid #888888;
    }
    .footerpageinside{
        display: flex;
        /* background-color: antiquewhite; */
        width: 70%;
        margin-left: 15%;
        height: 30vh;
    }
    .footerleft{
        width: 40%;
        /* background-color: aqua; */
        display: grid;
    }
    .logo{
        height: 5vh;
        width: 10vw;
    }
    .logoimg{
        height: 5vh;
        width: 10vw;
    }
    .email{
        display: flex;
        margin-top: -4vh;
    }
    .emailnext{
        height: 5vh;
        width: 10vw;
        border:1px solid #888888;
        border-radius: 5px;
    }
    .emailbutton{
        height: 5vh;
        width: 8vw;
        border-radius: 10px;
        border: none;
        font-size: 0.8rem;
        color: white;
        background: linear-gradient(101.12deg, #EB1484 27.35%, #C91CC3 99.99%, #C81CC5 100%, #C81CC5 100%);
    }
    .footercenter{
        width: 30%;
        /* background-color: #C91CC3; */
        display: grid;
    }
    .graphique{
        font-size: 1.2rem;
        font-weight: 600;
    }
    .how{
        margin-top: -4vh;
    }
    .contact{
        margin-top: -5vh;
    }
    .footerright{
        width: 30%;
        /* background-color: #EB1484; */
        display: grid;
    }
    .support{
        font-size: 1.2rem;
        font-weight: 600;
    }
    .help{
        margin-top: 3vh;
    }
    .footerbottom{
        display: flex;
        width: 60%;
        margin-left: 15%;
        margin-top: 5vh;
        justify-content: space-between;
    }
    .graphiquebottom{
        font-weight: bold;
        font-size: 1rem;
    }
    .socialicons{
        display: flex;
    }
    .igdiv{
        height: 5vh;
        width: 2.5vw;
    }
    .igimg{
        height: 4vh;
        width: 2vw;
    }
    .twitterdiv{
        height: 5vh;
        width: 2.5vw;
    }
    .twitterimg{
        height: 4vh;
        width: 2vw;
    }
}


