.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: black;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: black;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: red;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: top;
    height: 20%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #FFFFFF;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    height: 50%;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
    height: 50%;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    height: 50%;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  .menu-item{
      text-decoration: none;
  }
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    height: 50%;
  }
  .connectbtnnavmob{
    min-height: 4vh;
    min-width: 7vw;
    background: linear-gradient(101.12deg, #EB1484 27.35%, #C91CC3 99.99%, #C81CC5 100%, #C81CC5 100%);
    outline: none;
    border: none;
    border-radius: 10px;
    
}
.createbtnnavmob{
    color: #DA18A3;
    border: 2px solid #DA18A3;
    background: transparent;
    min-height: 4vh;
    min-width: 7vw;
    border-radius: 10px;
}
.connectbtndivnavmob{
    display: inline-block;
}
.createbtndivnavmob{
    display: inline-block;
}